<template>
  <section class="antar-barang">
    <div class="container-scroller">
      <div class="page-header">
        <h3 class="page-title">
          &nbsp;
        </h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
            <li class="breadcrumb-item">. . .</li>
            <li class="breadcrumb-item next-breadcrumb"><router-link class="nav-link" :to="`/operasional/antar-barang/${params.uuid}`"><span class="menu-title">Antar Barang - Detail Pengambilan Barang</span></router-link></li>						
            <li class="breadcrumb-item next-breadcrumb" aria-current="page"><router-link class="nav-link" :to="`/operasional/antar-barang/penerima-paket/${params.uuid}`"><span class="menu-title">Antar Barang - Detail Penerima Barang</span></router-link></li>
            <li class="breadcrumb-item active" aria-current="page">Antar Barang - Detail Paket</li>						
          </ol>
        </nav>
      </div>          
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <h2 class="col-xl-8 col-lg-6 mx-auto text-center pb-5" style="color: #00b050;font-weight: 800;">Antar Barang</h2>            
            <div class="col-xl-8 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5">
                <div class="card pb-4">
									<div class="brand-logo text-center">
										<img class="pt-5" src="@/assets/images/kebut/XMLID_1147_.png">
									</div>
									<div class="row pl-4">
										<h6 class="font-weight-bold col-lg-12">Berat Maksimal Paket : 25kg</h6>
										<h6 class="font-weight-bold col-lg-12">Batas Ukuran Paket : 60x60x60</h6>
										<h6 class="font-weight-bold col-lg-12">Gratis Waktu Tunggu : 15 Menit</h6>
									</div>																											        
                </div>

                <h4 class="pt-3 text-center">Detail Paket</h4>
                <form class="pt-3">
                  <div class="form-group">
                    <b-row>
                      <b-col lg="6"><label>Deskripsi Barang</label></b-col>
                    </b-row>
                    <input type="text" v-model="form.desc_package" class="form-control form-control-lg" placeholder="">
                  </div>
                  <div class="form-group">
                    <b-row>
                      <b-col lg="6"><label>Ukuran Barang</label></b-col>
                    </b-row>                    
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="selected"
                      :options="optionsRadio"
                      name="radio-options"
                    ></b-form-radio-group>                    
                    <!-- <input type="email" v-model="form.email" class="form-control form-control-lg" placeholder="(contoh patokan)"> -->
                  </div>
									<div class="row">
										<div class="col-lg-6">
											<div class="form-group">
												<b-row>
													<b-col lg="6"><label>Tanggal Ambil</label></b-col>
												</b-row>
												<date-picker
													:disabled-date="disabledBeforeToday"
													:default-value="new Date()"                  
													v-model="form.date_pickup"
													format="YYYY-MM-DD"
													date-format="YYYY-MM-DD"
													placeholder="Tanggal Ambil"
													type="date"
												></date-picker>
											</div>											
										</div>
										<div class="col-lg-6">
											<div class="form-group">
                        <b-row>
                          <b-col lg="6"><label>Jam Ambil</label></b-col>
                        </b-row>
                        <vue-timepicker v-model="form.time_pickup" format="HH:mm:ss"></vue-timepicker>                        
											</div>
										</div>										
									</div>
                  <div class="form-group">
                    <b-row class="mt-2">
                      <b-col lg="6"><label>Metode Pengiriman</label></b-col>
                      <!-- <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                        -->
                    </b-row>
                    <v-select v-model="optSelect" :options="dataOption1"></v-select>
                  </div>

                  <div class="mt-3">
                    <template v-if="alert.status != 0">
                      <h4 class="pt-3 text-center" style="color:#e91e63;">{{ alert.message }}</h4>
                    </template>
                    <b-button ref="containerButton" class="w-100 btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" style="background-color:#d2ddf7;" variant="info" @click="onNext">Lanjut</b-button>                    
                    <!-- <router-link class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" to="/">SIGN IN</router-link> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
      <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script>
import axios from 'axios'
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'login',
  data(){
    return{
      passwordHidden: false,
      params:{
        uuid: this.$route.params.uuid
      },
      errorMassage:0,
      form:{
        route: '',
        route_title: 'detail_paket',
        uuid_package_sizes : '',
        desc_package : '',
        date_pickup : '',
        time_pickup : '',
        uuid_shipping_methods : '',        
        created_by: localStorage.getItem("uuid"),
      },
      alert:{
        status: 0,
        message:'',
      },
      data: [],
      dataOption1: [],
      optSelect: '',
      optionsRadio: [],
      selected: 'first', 
    }
  },
  methods:{
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),    
    disabledBeforeTodayAndAfterAWeek: (date) => packageGlobals.disabledBeforeTodayAndAfterAWeek(date),
    disabledBeforeToday: (date) => packageGlobals.disabledBeforeToday(date),
    getShippingMethod: function(search,page,limit) {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/reference/shipping-method?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)            
			.then((response) => {
        // this.dataOptionServices = 
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            id : element.uuid,
            label : element.name
          }
          this.dataOption1.push(pushData)
        });
			})
    },
    getPackageSize: function (search, page, limit) {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/master/package-size?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)      
			.then((response) => {
        // this.dataOptionServices = 
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            value : element.uuid,
            text : element.name
          }
          this.optionsRadio.push(pushData)
        });
			})							
    },
    getData: function (uuid) {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/operational/antar-barang/${uuid}`,packageGlobals.configAxios)
			.then((response) => {
        this.form.route = '/operasional/antar-barang/detail-paket/'+this.params.uuid
        this.form.uuid_package_sizes = response.data.result.uuid_package_sizes
        this.form.desc_package = response.data.result.desc_package
        this.form.date_pickup = this.normalDateTime(response.data.result.date_pickup).replace(' 0:0:0', '')        
        this.form.time_pickup = response.data.result.time_pickup
        this.form.uuid_shipping_methods = response.data.result.uuid_shipping_methods        
			})      
    },           
    // onNext(evt) {
    onNext() {  
      this.form.uuid_shipping_methods = this.optSelect['id']                  
      this.form.uuid_package_sizes = this.selected
      this.form.time_pickup = this.form.time_pickup['HH']+':'+this.form.time_pickup['mm']+':'+this.form.time_pickup['ss']
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
      axios.put(`${packageStore.mainApi}/api/web/operational/antar-barang/${this.params.uuid}`,this.form)
      .then((response) => {
        this.data = response
        this.$router.push(`/operasional/antar-barang/pengukuran-paket/${this.params.uuid}`)        
      })        
    }
  },
  mounted() {
    this.getData(this.params.uuid)        
		this.getPackageSize('-', 1, 999)
    this.getShippingMethod('-',1,999)    
  }  
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}

.next-breadcrumb a
{
	padding-top: 0px;
}

.custom-control-label{
  padding-top: 4px;
  font-size: 16px!important;
}
</style>